<template>
  <DefaultDesktop v-if="!isStylize && isDesktopSize">
    <slot />
  </DefaultDesktop>
  <CustomizeDesktop v-else-if="isStylize && isDesktopSize">
    <slot />
  </CustomizeDesktop>

  <DefaultMobile v-else>
    <slot />
  </DefaultMobile>
</template>

<script lang="ts" setup>
import CustomizeDesktop from './customize-desktop.vue';
import DefaultDesktop from './default-desktop.vue';
import DefaultMobile from '~/shared/layouts/default-mobile.vue';

const { isDesktopSize } = useWindowSize();
const route = useRoute();
const isStylize = computed(() => {
  return route.name === 'character-stylize' || route.name === 'character-draft-stylize';
});

useCanonicalUrl();
</script>
