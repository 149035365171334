<template>
  <ion-menu
    @ionDidOpen="showContent = true"
    @ionWillClose="showContent = false"
    menu-id="end"
    side="end"
    class="menu"
    content-id="main-content"
    :swipeGesture="false"
    :style="`--width:100vw;`"
  >
    <ion-header>
      <ion-toolbar color="white">
        <div class="d-flex align-items-center justify-content-between">
          <ion-button color="transparent" class="clickable-item-hov" @click="close">
            <i class="ti-angle-left" style="color: var(--ion-color-primary)" color="primary" />
          </ion-button>
          <div><ion-title>Edit Theme</ion-title></div>
          <div>
            <ion-button v-if="isEmpty(template)" @click="applyTemplate" color="primary" class="d-flex save-btn"
              >Apply</ion-button
            >
            <ion-button v-else :disabled="isSaving" @click="saveTemplate" class="d-flex save-btn">
              <ch-loading size="sm" v-if="isSaving" />
              <div v-else>
                <i class="ti-save mr-1" />

                <span>Save</span>
              </div>
            </ion-button>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="showContent" :style="`--background: ${bg}`">
      <div :style="prfBackgroundImageStyle">
        <div
          class="content position-relative"
          :class="
            get(userTryOnAnimation, 'extra.bgRepeat') || get(currentTemplate, 'data.background_overlay.extra.bgRepeat')
              ? 'page-repeat-overlay'
              : 'page-overlay'
          "
          :style="backgroundImageStyle"
        >
          <div class="p-3">
            <!-- <WelcomeThemeNote style="border-radius: 16px" /> -->
            <CharacterMobCustomizeProfile :setMargin="true" :displayContainer="true" @tryOn="closeModal" />
          </div>
        </div>
      </div>
    </ion-content>
    <stylize-character-modals
      :is-open="isModalOpen"
      :is-switch="false"
      :customize="currentTemplate"
      @close="closeStylizeModal"
      @dismiss="dismissModal"
      @applied="appliedModal"
      @loadPage="loadCharProfile"
    />
  </ion-menu>
</template>

<script lang="ts" setup>
import StylizeCharacterModals from '@/shared/modals/StylizeCharacterModal.vue';
import { menuController } from '@ionic/vue';
import { updateCharTemplate } from '@/shared/actions/characters';
import CharacterMobCustomizeProfile from '~/shared/pages/character-profile-new/character-mob-customize-profile.vue';
import { characterStylizeStore } from '../pinia-store/character-stylize';
import { toast } from '../native/toast';
import { authStore } from '../pinia-store/auth';
import { useBackgroundOverlay } from '~/composables/useBackgroundOverlay';
import { useBackgroundImage } from '~/composables/useBackgroundImage';
const { currentTemplate, customizeCharacter, template } = characterStylizeStore();
const { backgroundImageStyle } = useBackgroundOverlay();
const { prfBackgroundImageStyle, mode } = useBackgroundImage();
const isModalOpen = ref(false);
const showContent = ref(false);
const route = useRoute();
const istoast = ref(false);
const router = useRouter();
const { userTryOnAnimation } = authStore();
const { bg: b } = useCharPageColors();
const bg = computed(() => b.value.replace(' !important', ''));
const emits = defineEmits(['showtoast', 'applied', 'close']);
const props = defineProps({
  isClose: {
    type: Boolean,
    default: false,
  },
});
const isClose = toRef(props, 'isClose');
const close = async () => {
  showContent.value = false;
  await menuController.close('end');
};
const applyTemplate = () => {
  isModalOpen.value = true;
};
const isSaving = ref(false);
const saveTemplate = async () => {
  isSaving.value = true;
  try {
    const payload = {
      data: currentTemplate.value?.data,
    };

    const temp_id = !isEmpty(get(currentTemplate.value, 'id'))
      ? currentTemplate.value.id
      : customizeCharacter.value?.template_applied;
    await updateCharTemplate(temp_id, true, payload);
    await toast.show('Character Customization saved successfully', 'nonative', 'success');
  } catch (_err) {
    await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
  }
  setTimeout(() => {
    isSaving.value = false;
    close();
  }, 100);
};
const loadCharProfile = () => {
  isModalOpen.value = false;
  getRoute();
};
const getRoute = () => {
  route.name === 'character-stylize'
    ? router.push({ name: 'character-profile-new', params: { slug: get(customizeCharacter.value, 'entity.slug') } })
    : router.push({
        name: 'character-profile-draft-new',
        params: { id: get(customizeCharacter.value, 'entity_id') },
      });
};

const dismissModal = () => {
  isModalOpen.value = false;
  emits('applied');
  close();
};
const closeModal = () => {
  isModalOpen.value = false;
  emits('close');
  close();
};
watch(isClose, () => {
  if (isClose.value) {
    closeModal();
  }
});
const appliedModal = () => {
  closeStylizeModal();

  if (!customizeCharacter.value?.entity?.slug) return;

  router.push({ name: 'character-profile-new', params: { slug: get(customizeCharacter.value, 'entity.slug') } });
  emits('showtoast', (istoast.value = true));
  close();
};
const closeStylizeModal = () => {
  isModalOpen.value = false;
};

const imageMode = computed(() => {
  return mode.value === 'no-repeat' ? '100% 100%' : '100% auto';
});
</script>

<style lang="sass" scoped>
@media(max-width: 340px)
  .menu
    --min-width: 100vw
.prf-bg-image
  width: 100%
  background-size: v-bind(imageMode) !important
.content
  padding-bottom: calc(var(--safe-ios-margin)/3) !important
.save-btn
  --background: #00B4C5
  --border-radius: 20px
</style>
